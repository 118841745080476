import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'http://nordtest-crm.duckdns.org/api',
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        console.error('API error:', error);
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.request.use(request => {
    // console.log('Starting Request', request);
    return request;
});

export default axiosInstance;
