import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from './EditRow.module.css';
import Form from '@/components/Form/Form';
import MainStoreContext from '../../stores/MainStore';
import { getRow, putRow } from '../../services/api/table/common';
import { getDropdown } from '../../services/api/dropdown';
import { useNavigate } from 'react-router-dom';

const EditRow = () => {
    const [formData, setFormData] = useState({});
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState({});
    const { entityInternalName, attributes, row, refreshTable } = useContext(MainStoreContext);
    const navigate = useNavigate();

    const localStorageKey = `EditRowData_${entityInternalName}`;
    const dropdownLocalStorageKey = `DropdownOptions_${entityInternalName}`;

    useEffect(() => {
        const loadData = async () => {
            const savedFormData = JSON.parse(localStorage.getItem(localStorageKey));
            const savedDropdownOptions = JSON.parse(localStorage.getItem(dropdownLocalStorageKey));

            if (savedFormData) {
                setFormData(savedFormData);
                setIsSubmitButtonDisabled(!isFormValid(savedFormData));
            } else {
                const initialFormData = {};
                try {
                    const response = await getRow(entityInternalName, row.public_id);
                    const resp = response.data;
                    attributes.forEach(attr => {
                        initialFormData[attr.public_name] = resp.values[attr._id] || '';
                    });
                    setFormData(initialFormData);
                    localStorage.setItem(localStorageKey, JSON.stringify(initialFormData));
                } catch (error) {
                    console.error('Error fetching row data:', error);
                }
            }

            if (savedDropdownOptions) {
                setDropdownOptions(savedDropdownOptions);
            } else {
                const dropdownFields = attributes.filter(attr => attr.dropdown_name);

                try {
                    const options = await Promise.all(dropdownFields.map(async (attr) => {
                        const response = await getDropdown(attr.dropdown_name);
                        return { [attr.public_name]: response.data.values };
                    }));

                    const optionsMap = options.reduce((acc, option) => ({ ...acc, ...option }), {});
                    setDropdownOptions(optionsMap);
                    localStorage.setItem(dropdownLocalStorageKey, JSON.stringify(optionsMap));
                } catch (error) {
                    console.error('Error fetching dropdown options:', error);
                }
            }

            const handlePopState = () => {
                localStorage.removeItem(localStorageKey);
                localStorage.removeItem(dropdownLocalStorageKey);
            };

            window.addEventListener('popstate', handlePopState);

            return () => {
                window.removeEventListener('popstate', handlePopState);
            };
        };

        loadData();
    }, []);

    const saveToLocalStorage = () => {
        localStorage.setItem(localStorageKey, JSON.stringify(formData));
    };

    useEffect(() => {
        window.addEventListener('beforeunload', saveToLocalStorage);
        return () => {
            window.removeEventListener('beforeunload', saveToLocalStorage);
        };
    }, [formData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue;

        if (type === 'checkbox') {
            newValue = checked;
        } else {
            newValue = value;
        }

        const newFormData = {
            ...formData,
            [name]: newValue
        };

        setFormData(newFormData);
        localStorage.setItem(localStorageKey, JSON.stringify(newFormData));

        setErrors(prevErrors => {
            const { [name]: removedError, ...remainingErrors } = prevErrors;
            return remainingErrors;
        });

        setIsSubmitButtonDisabled(!isFormValid(newFormData));
    };

    const isFormValid = (formData) => {
        return attributes.every(attr => {
            if (attr.is_modifiable && !attr.is_nullable) {
                return formData[attr.public_name] !== '';
            }
            return true;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (Object.keys(errors).length > 0) {
            console.error(errors);
            return;
        } else {
            const payload = {};
            attributes.forEach(attr => {
                let value = formData[attr.public_name];
                if (attr.column_type === 'bool') {
                    value = value ? 'True' : 'False';
                }
                payload[attr._id] = value;
            });

            putRow(entityInternalName, row.public_id, payload)
                .then(resp => {
                    refreshTable();
                    navigate('/', {replace: true });
                    localStorage.removeItem(localStorageKey);
                    localStorage.removeItem(dropdownLocalStorageKey);
                })
                .catch(error => {
                    console.log(error);
                    if (error.response && error.response.data) {
                        const backendErrors = error.response.data;
                        const mappedErrors = backendErrors.reduce((acc, { attribute_id, detail, error }) => {
                            const attribute = attributes.find(attr => attr._id === attribute_id);
                            if (attribute) {
                                switch (error) {
                                    case 'wrong_type':
                                        acc[attribute.public_name] = 'Некорректное значение';
                                        break;
                                    case 'mandatory':
                                        acc[attribute.public_name] = 'Поле обязательно для заполнения';
                                        break;
                                    case 'not_modifiable':
                                        acc[attribute.public_name] = 'Поле нельзя редактировать';
                                        break;
                                    case 'wrong_dropdown_value':
                                        acc[attribute.public_name] = 'Некорректное значение';
                                        break;
                                    default:
                                        acc[attribute.public_name] = detail;
                                }
                            }
                            return acc;
                        }, {});
                        setErrors(mappedErrors);
                    }
                });
        }
    };

    const generateFields = (attributes) => {
        const fields = [];
        for (let i = 0; i < attributes.length; i++) {
            const attr = attributes[i];
            let field = {
                name: attr.public_name,
                id: `field-${i}`,
                labelBefore: attr.public_name,
                placeholder: 'Введите данные',
                required: !attr.is_nullable,
                description: attr.description
            };

            if (attr.dropdown_name) {
                field.type = 'select';
                field.options = dropdownOptions[attr.public_name] || [];
            } else {
                switch (attr.column_type) {
                    case 'bool':
                        field.type = 'checkbox';
                        field.labelAfter = 'Да';
                        field.required = false;
                        field.value = attr.default_value !== undefined ? attr.default_value : false;
                        break;
                    case 'int':
                    case 'float':
                        field.placeholder = 'Введите число';
                        break;
                    case 'long_string':
                        field.type = 'textarea';
                        break;
                    default:
                        field.type = 'text';
                }
            }
            fields.push(field);
        }
        return fields;
    };

    const fields = useMemo(() => generateFields(attributes), [attributes, dropdownOptions]);

    return (
        <div className={styles.container}>
            <Form
                title={'Редактировать строку'}
                fields={fields}
                onChange={handleChange}
                onSubmit={handleSubmit}
                formData={formData}
                errors={errors}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
            />
        </div>
    );
};

export default EditRow;
