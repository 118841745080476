import React, {useContext, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import Table from "@/components/Table/Table.jsx";
import Dropdown from "@/components/Dropdown/Dropdown.jsx";
import MainStoreContext from "@/stores/MainStore.jsx";
import styles from './Entity.module.css';

const Entity = observer(({ entity }) => {
  const { rows, entities, selectedAttributes, setEntityInternalName, loadAttributes } = useContext(MainStoreContext);
  const internalEntities = entities.filter((internalEntity) => internalEntity.public_name === entity);
  const entityInternalName = internalEntities[0].internal_name;

  useEffect(() => {
    setEntityInternalName(entityInternalName);
    loadAttributes(entityInternalName);
  }, [entityInternalName, setEntityInternalName, loadAttributes]);

  return (
    <>
      <div className={styles['filters-container']}>
        <Dropdown
          label={'Выбрать свойства'}
        />
        <button className={styles['nav-button']}>
          <NavLink to={'/add-row'}>Добавить {entity}</NavLink>
        </button>
      </div>
      {selectedAttributes.length > 0 ? (
        <Table />
      ) : (
        <div className={styles.noAttributes}>
          Пожалуйста, выберите свойства для отображения
        </div>
      )}
    </>
  );
});

export default Entity;
