import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {BrowserRouter} from "react-router-dom";
import { MainStoreProvider } from './stores/MainStore.jsx';
import { AuthStoreProvider } from './stores/AuthStore.jsx';
import AppRouter from "@/components/logic/AppRouter.jsx";

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AuthStoreProvider>
      <MainStoreProvider>
        <BrowserRouter>
          <AppRouter/>
        </BrowserRouter>
      </MainStoreProvider>
    </AuthStoreProvider>
  </React.StrictMode>,
)
