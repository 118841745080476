import React, { useContext, useState, useEffect } from 'react';
import styles from './Table.module.css';
import RowMenu from "@/components/RowMenu/RowMenu.jsx";
import MainStoreContext from "@/stores/MainStore.jsx";
import { DateTime } from 'luxon';

const Table = () => {
    const { entity, selectedAttributes, rows, refreshTable } = useContext(MainStoreContext);
    const [visibleRowId, setVisibleRowId] = useState(null);

    useEffect(() => {
        refreshTable();
    }, [refreshTable, entity, selectedAttributes]);

    return (
        <div className={styles['table-container']}>
            <table className={styles.table}>
                <thead>
                    <tr className={styles.tr}>
                        <th key="id" className={styles.th}>ID</th>
                        {selectedAttributes.map((attr) => (
                            <th key={attr._id} className={styles.th}>{attr.public_name}</th>
                        ))}
                        <th key="date" className={styles.th}>Дата создания</th>
                        <th key="empty" className={styles.th}></th>
                    </tr>
                </thead>

                <tbody>
                    {rows.map((row) => {
                        const date = DateTime.fromISO(row.creation_date, { zone: "utc" });
                        const localDate = date.setZone(DateTime.local().zoneName);
                        const formattedLocalDate = localDate.toLocaleString(DateTime.DATETIME_SHORT);
                        return (
                            <tr key={row.public_id} className={styles.tr}>
                                <td className={styles.td}>{row.public_id}</td>
                                {selectedAttributes.map((attr) => (
                                    <td key={attr._id} className={styles.td}>
                                        {row.values[attr._id]}
                                    </td>
                                ))}
                                <td className={styles.td}>{formattedLocalDate}</td>
                                <td key={`menu-${row.public_id}`} className={`${styles.td} ${styles["td-menu"]}`}>
                                    <RowMenu
                                        row={row}
                                        isVisibleRow={visibleRowId === row.public_id}
                                        setVisibleRowId={setVisibleRowId}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
