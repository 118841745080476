import React from 'react'
import { useNavigate } from 'react-router-dom';
import AuthorizationForm from '../../components/AuthorizationForm/AuthorizationForm';
import styles from './AuthorizationPage.module.css';

const AuthorizationPage = () => {

  return (
    <div className={styles.Page}>
        <AuthorizationForm/>
    </div>
  )
}

export default AuthorizationPage