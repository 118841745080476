import React, { Component } from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from './Tab.module.css';
import TabNavBar from "@/components/Tab/TabNavBar.jsx";

class TabBar extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        className: '',
    };

    state = {
        activeTab: this.props.activeTab || localStorage.getItem('lastActiveTab') || null,
    }

    componentDidMount() {
        const { children = [] } = this.props;
        const initialTab = this.state.activeTab || this.getChildrenLabels(children)[0];
        this.setActiveTab(initialTab);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children) {
            const { children } = this.props;
            if (children.length > 0) {
                const initialTab = this.state.activeTab || this.getChildrenLabels(children)[0];
                this.setActiveTab(initialTab);
            }
        }
    }

    getChildrenLabels = (children) => {
        return React.Children.map(children, (child) => child.props.label);
    }

    setActiveTab = (activeTab) => {
        const { activeTab: currentTab } = this.state;

        if (currentTab !== activeTab) {
            this.setState({ activeTab }, () => {
                localStorage.setItem('lastActiveTab', activeTab);
            });
        }
    }

    renderTabs = () => {
        const { children = [] } = this.props;
        const { activeTab } = this.state;

        return this.getChildrenLabels(children).map((navLabel) => (
            <TabNavBar
                key={navLabel}
                navLabel={navLabel}
                isActive={activeTab === navLabel}
                className={classNames({ active: activeTab === navLabel })}
                onChangeActiveTab={this.setActiveTab}
            />
        ));
    }

    render() {
        const { activeTab } = this.state;
        const { children, className, ...attrs } = this.props;

        const classes = classNames(
            styles['tab-bar'],
            className,
        );

        return (
            <div className={classes} {...attrs}>

                <div className={styles['tab-heading']}>
                    <h1>{activeTab}</h1>
                </div>

                <div className={styles['tab-bar-labels-container']}>
                    {this.renderTabs()}
                </div>

                <div className={styles['tab-container']}>
                    {React.Children.map(children, (child) =>
                        React.cloneElement(child, { activeTab })
                    )}
                </div>

            </div>
        );
    }
}

export default TabBar;