import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <>
            <Link to={'/'}>To Home</Link>
        </>
    );
};

export default NotFound;