import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import styles from './Tab.module.css';

const TabBarItem = ({label, children = null, activeTab = '', ...attrs}) => {

    const classes = classNames(
        styles['tab-bar-item'],
    );

    return (
        label === activeTab && (
            <div className={classes} {...attrs}>
                {children}
            </div>
        )
    );
};

TabBarItem.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node,
    activeTab: PropTypes.string,
}

export default TabBarItem;