import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Form.module.css';

const InputField = React.memo(({
    id,
    name,
    type,
    placeholder = 'Введите значение',
    value,
    onChange,
    required = false,
    error,
    labelBefore,
    labelAfter,
    options
}) => {
    const inputClassName = classNames(styles.input, {
        [styles.error]: error
    });

    return (
        <div className={styles['form-control']}>
            {labelBefore && (
                <label htmlFor={id} className={styles.labelBefore}>
                    {labelBefore}
                    {required && <span> *</span>}
                </label>
            )}
            {type === 'select' ? (
                <select
                    id={id}
                    name={name}
                    className={inputClassName}
                    value={value}
                    onChange={onChange}
                    required={required}
                >
                    <option value="">Выберите значение</option>
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            ) : type === 'textarea' ? (
                <textarea
                    rows={5}
                    cols={50}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    className={inputClassName}>                     
                </textarea>
            ) : (
                <input
                    type={type}
                    id={id}
                    name={name}
                    className={inputClassName}
                    placeholder={placeholder}
                    value={value}
                    checked={type === 'checkbox' ? value : undefined}
                    onChange={onChange}
                    required={required}
                />
            )}
            {type === 'checkbox' && labelAfter && (
                <label htmlFor={id} className={styles.labelAfter}>
                    {labelAfter}
                </label>
            )}
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
});

InputField.displayName = 'InputField';
InputField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'tel', 'checkbox', 'select', 'datetime-local']),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
    labelBefore: PropTypes.string,
    labelAfter: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string)
};

export default InputField;
