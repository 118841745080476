import React from 'react';
import PropTypes from 'prop-types';
import InputField from './InputField';
import styles from './Form.module.css';

const Form = React.memo(({
    fields,
    title,
    onSubmit,
    onChange,
    formData,
    errors,
    isSubmitButtonDisabled
}) => {
    return (
        <div className={styles.form}>
            <h1>{title}</h1>
            <form onSubmit={onSubmit} className={styles.formContainer}>
                <section>
                    {fields.map(({ id, name, type, labelBefore, placeholder, required, labelAfter, options }) => (
                        <div key={id} className={styles.inputFieldContainer}>
                            <InputField
                                id={id}
                                name={name}
                                type={type}
                                labelBefore={labelBefore}
                                placeholder={placeholder}
                                value={formData[name]}
                                onChange={onChange}
                                required={required}
                                error={errors[name]}
                                options={options}
                                labelAfter={type === 'checkbox' ? labelAfter : null}
                            />
                        </div>
                    ))}
                </section>
                <section className={styles.submit}>
                    <button
                        type="submit"
                        className={isSubmitButtonDisabled ? styles.buttonDisabled : styles.button}
                        disabled={isSubmitButtonDisabled}
                    >
                        Отправить
                    </button>
                </section>
            </form>
        </div>
    );
});

Form.propTypes = {
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'tel', 'checkbox', 'datetime-local', 'select']),
            labelBefore: PropTypes.string.isRequired,
            placeholder: PropTypes.string,
            required: PropTypes.bool,
            description: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.string)
        })
    ).isRequired,
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isSubmitButtonDisabled: PropTypes.bool.isRequired,
};

export default Form;
