import { createContext, useContext } from "react";
import {getAttributes} from "@/services/api/table/common/attributes/index.js";
import {getEntity} from "@/services/api/admin/entity/index.js";
import { makeAutoObservable, runInAction, action } from 'mobx';
import { getTable } from "@/services/api/table/common/index.js";

class MainStore {
    _entityInternalName = null;
    _row = null;
    _rows = [];
    _attributes = [];
    _selectedAttributes = [];
    _entities = [];
    _error = null;

    constructor() {
        this._entityInternalName = null;
        this._row = null;
        this._rows = [];
        this._attributes = [];
        this._selectedAttributes = [];
        this._entities = [];
        const savedState = JSON.parse(localStorage.getItem('mainStoreState')) || {};

        this._entityInternalName = savedState._entityInternalName || null;
        this._row = savedState._row || null;
        this._rows = savedState._rows || [];
        this._attributes = savedState._attributes || [];
        this._selectedAttributes = savedState._selectedAttributes || [];
        this._entities = savedState._entities || [];
        this._error = savedState._error || null;

        makeAutoObservable(this, {
            setEntityInternalName: action,
            setRow: action,
            setRows: action,
            setAttributes: action,
            setSelectedAttributes: action,
            setEntities: action,
            setError: action,
            refreshTable: action.bound
        });
    }

    saveState = () => {
        const state = {
            _entityInternalName: this._entityInternalName,
            _row: this._row,
            _rows: this._rows,
            _attributes: this._attributes,
            _selectedAttributes: this._selectedAttributes,
            _entities: this._entities,
            _error: this._error,
        };
        localStorage.setItem('mainStoreState', JSON.stringify(state));
    }

    setEntityInternalName = (entityInternalName) => {
        this._entityInternalName = entityInternalName;
        this.saveState();
    }

    setRow = (row) => {
        this._row = row;
        this.saveState();
    }

    setRows = (rows) => {
        this._rows = rows;
        this.saveState();
    }

    setAttributes = (attributes) => {
        this._attributes = attributes;
        this.saveState();
    }

    setEntities = (entities) => {
        this._entities = entities;
        this.saveState();
    }

    setError = (error) => {
        this._error = error;
        this.saveState();
    }

    setSelectedAttributes = (selectedAttributes) => {
        this._selectedAttributes = selectedAttributes;
    }

    get row() {
        return JSON.parse(JSON.stringify(this._row));
    }

    get entityInternalName() {
        return this._entityInternalName;
    }

    get rows() {
        return JSON.parse(JSON.stringify(this._rows));
    }

    get attributes() {
        return this._attributes;
    }

    get selectedAttributes() {
        return this._selectedAttributes
    }

    get entities() {
        return JSON.parse(JSON.stringify(this._entities));
    }

    refreshTable = () => {
        getTable(this._entityInternalName)
            .then(response => {
                runInAction(() => {
                    this.setRows(response.data);
                });
            })
            .catch(error => {
                runInAction(() => {
                    this.setError(error);
                });
            });
    }

    initializeSelectedAttributes = () => {
        this.setSelectedAttributes(this._attributes);
    }

    loadAttributes = (entityInternalName) => {
        getAttributes(entityInternalName)
            .then(response => {
                this.setAttributes(response.data);
                this.initializeSelectedAttributes();
            })
            .catch(error => {
                this.setError(error);
            });
        }

    loadEntities = () => {
        return getEntity()
            .then(response => {
                this.setEntities(response.data);
                return response.data.map(entity => entity.public_name);
            })
            .catch(error => {
                this.setError(error);
                return [];
            });
    }
}


const MainStoreContext = createContext();

export const MainStoreProvider = ({ children }) => {

    return (
        <MainStoreContext.Provider value={new MainStore()}>
            {children}
        </MainStoreContext.Provider>
    );
};

export default MainStoreContext;
