import React, { useContext, useEffect, useState } from 'react';
import TabBar from "@/components/Tab/TabBar.jsx";
import TabBarItem from "@/components/Tab/TabBarItem.jsx";
import styles from './Main.module.css';
import Entity from "@/components/Entity/Entity.jsx";
import { getEntity } from '@/services/api/admin/entity/index.js';
import MainStoreContext from '../../stores/MainStore';
import { getAttribute } from '@/services/api/admin/attribute/index.js';

const Main = () => {
    const [visiblePublicNamesEntities, setVisiblePublicNamesEntities] = useState([]);
    const { setEntities } = useContext(MainStoreContext);

    useEffect(() => {
        const fetchEntities = async () => {
            const entitiesResponse = await getEntity();
            setEntities(entitiesResponse.data);

            const publicNamesWithAttributes = [];
            const newEntityMap = new Map();

            try {
                const attributesResponse = await getAttribute();

                for (let attribute in attributesResponse.data) {
                    newEntityMap.set(attributesResponse.data[attribute]["entity_id"], true);
                }

                const entities = entitiesResponse.data;

                entities.forEach(entity => {
                    if (newEntityMap.has(entity._id)) {
                        publicNamesWithAttributes.push(entity.public_name);
                        newEntityMap.set(entity.internal_name, entity.public_name);
                    }
                });

            } catch (error) {
                console.error(error);
            }

            setVisiblePublicNamesEntities(publicNamesWithAttributes);
        };

        fetchEntities();
    }, [setEntities]);

    return (
        <div className={styles['main-container']}>
            <div className={styles['flex-container']}>
                <TabBar>
                    {visiblePublicNamesEntities.map((entity, index) => (
                        <TabBarItem
                            key={index}
                            label={entity}
                        >
                            <Entity entity={entity} />
                        </TabBarItem>
                    ))}
                </TabBar>
            </div>
        </div>
    );
};

export default Main;
