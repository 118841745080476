import { makeAutoObservable } from "mobx";
import { createContext } from "react";


class AuthStore {

    constructor() { 
        this._isAuthenticated = false;
        this._user = null;
        makeAutoObservable(this);
    }

    get isAuthenticated() {
        return this._isAuthenticated
    }

    get user() {
        return this._user
    }

    login(user) {
        this._isAuthenticated = true
        this._user = user
    }

    logout() {
        this._isAuthenticated = false
        this._user = null
    }

    reset() {
        this._isAuthenticated = false
        this._user = null
    }
}

const AuthStoreContext = createContext()

export const AuthStoreProvider = ({ children }) => {

    return (
        <AuthStoreContext.Provider value={new AuthStore()}>
            {children}
        </AuthStoreContext.Provider>
    )
}

export default AuthStoreContext