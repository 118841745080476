import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './AddRow.module.css';
import Form from '@/components/Form/Form';
import MainStoreContext from '../../stores/MainStore';
import {postRow} from '../../services/api/table/common';
import {useNavigate} from 'react-router-dom';
import {getDropdown} from '../../services/api/dropdown';

const AddRow = observer(() => {
    const [formData, setFormData] = useState({});
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState({});
    const { entityInternalName, attributes, refreshTable } = useContext(MainStoreContext);
    const navigate = useNavigate();

    const localStorageKey = `AddRowData_${entityInternalName}`;

    useEffect(() => {
        if (attributes.length === 0) return;

        const initialFormData = {};
        const dropdownFields = attributes.filter(attr => attr.dropdown_name);

        const fetchDropdownOptions = async () => {
            try {
                const options = await Promise.all(dropdownFields.map(async (attr) => {
                    const response = await getDropdown(attr.dropdown_name);
                    return { [attr.public_name]: response.data.values };
                }));

                const optionsMap = options.reduce((acc, option) => ({ ...acc, ...option }), {});
                setDropdownOptions(optionsMap);
            } catch (error) {
                console.error('Error fetching dropdown options:', error);
            }
        };

        fetchDropdownOptions();

        attributes.forEach(attr => {
            initialFormData[attr.public_name] = attr.column_type === 'bool' ? false : '';
        });

        const savedData = JSON.parse(localStorage.getItem(localStorageKey));
        if (savedData) {
            setFormData(savedData);
            setIsSubmitButtonDisabled(!isFormValid(savedData));
        } else {
            setFormData(initialFormData);
            setIsSubmitButtonDisabled(!isFormValid(initialFormData));
        }

        const handlePopState = () => {
            localStorage.removeItem(localStorageKey);
        };

        window.addEventListener('popstate', handlePopState);
        
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue;

        if (type === 'checkbox') {
            newValue = checked;
        } else {
            newValue = value;
        }

        const newFormData = {
            ...formData,
            [name]: newValue
        };

        setFormData(newFormData);
        setErrors(prevErrors => {
            const { [name]: removedError, ...remainingErrors } = prevErrors;
            return remainingErrors;
        });
        setIsSubmitButtonDisabled(!isFormValid(newFormData));
        localStorage.setItem(localStorageKey, JSON.stringify(newFormData));
    };

    const isFormValid = (formData) => {
        return attributes.every(attr => {
            if (attr.is_modifiable && !attr.is_nullable) {
                return formData[attr.public_name] !== '';
            }
            return true;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (Object.keys(errors).length > 0) {
            console.error(errors);
            return;
        } else {
            const payload = {};
            attributes.forEach(attr => {
                let value = formData[attr.public_name];
                if (attr.column_type === 'bool') {
                    value = value ? 'True' : 'False';
                }
                if (value !== '') {
                    payload[attr._id] = value;
                }
            });

            postRow(entityInternalName, payload)
                .then(resp => {
                    console.log(resp);
                    refreshTable();
                    navigate('/', {replace: true });
                    localStorage.removeItem(localStorageKey); 

                })
                .catch(error => {
                    console.log(error);
                    if (error.response && error.response.data) {
                        const backendErrors = error.response.data;
                        const mappedErrors = backendErrors.reduce((acc, { attribute_id, detail, error }) => {
                            const attribute = attributes.find(attr => attr._id === attribute_id);
                            if (attribute) {
                                switch (error) {
                                    case 'wrong_type':
                                        acc[attribute.public_name] = 'Некорректное значение';
                                        break;
                                    case 'mandatory':
                                        acc[attribute.public_name] = 'Поле обязательно для заполнения';
                                        break;
                                    case 'not_modifiable':
                                        acc[attribute.public_name] = 'Поле нельзя редактировать';
                                        break;
                                    case 'wrong_dropdown_value':
                                        acc[attribute.public_name] = 'Некорректное значение';
                                        break;
                                    default:
                                        acc[attribute.public_name] = detail;
                                }
                            }
                            return acc;
                        }, {});
                        setErrors(mappedErrors);
                    }
                });
            }
    };

    const generateFields = (attributes) => {
        const fields = [];
        for (let i = 0; i < attributes.length; i++) {
            const attr = attributes[i];
            let field = {
                name: attr.public_name,
                id: `field-${i}`,
                labelBefore: attr.public_name,
                placeholder: 'Введите данные',
                required: !attr.is_nullable,
                description: attr.description
            };

            if (attr.dropdown_name) {
                field.type = 'select';
                field.options = dropdownOptions[attr.public_name] || [];
            } else {
                switch (attr.column_type) {
                    case 'bool':
                        field.type = 'checkbox';
                        field.labelAfter = 'Да';
                        field.required = false;
                        field.value = attr.default_value !== undefined ? attr.default_value : false;
                        break;
                    case 'int':
                    case 'float':
                        field.placeholder = 'Введите число';
                        break;
                    case 'long_string':
                        field.type = 'textarea';
                        break;
                    default:
                        field.type = 'text';
                }
            }
            fields.push(field);
        }
        return fields;
    };

    const fields = useMemo(() => generateFields(attributes), [attributes, dropdownOptions]);

    return (
        <div className={styles.container}>
            <Form
                title={'Добавить строку'}
                fields={fields}
                onChange={handleChange}
                onSubmit={handleSubmit}
                formData={formData}
                errors={errors}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
            />
        </div>
    );
});

export default AddRow;
