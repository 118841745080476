import axiosInstance from '../../axiosInstance.jsx';

//get all rows from the Table
export const getTable = (internal_name, skip, limit) => {
     return axiosInstance.get('/table/common/', {
         params: {
             internal_name,
             skip,
             limit
         }
     });
}

//insert a row into the Table
export const postRow = (internal_name, data) => {
    return axiosInstance.post('/table/common/', data, {
        params: {
            internal_name
        }
    });
};

//get row from Table(internal_name)
export const getRow = (internal_name, public_id) => {
    return axiosInstance.get(`/table/common/${public_id}`, {
        params: {
            internal_name
        }
    });
};

//update row in Table(internal_name))
export const putRow = (internal_name, public_id, data) => {
    return axiosInstance.put(`/table/common/${public_id}`, data, {
        params: {
            internal_name
        }
    });
};

//delete row from Table(internal_name)
export const deleteRow = (internal_name, public_id) => {
    return axiosInstance.delete(`/table/common/${public_id}`, {
        params: {
            internal_name
        }
    });
};
