import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './RowMenu.module.css';
import MainStoreContext from '../../stores/MainStore';
import { deleteRow } from "@/services/api/table/common/index.js";

const RowMenu = ({ row, isVisibleRow, setVisibleRowId }) => {
  const { setRow, entityInternalName, refreshTable } = useContext(MainStoreContext);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const toggleMenu = () => {
    setRow(row);
    if (!isVisibleRow) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: buttonRect.bottom,
        left: buttonRect.left
      });
    }
    setVisibleRowId(isVisibleRow ? null : row["public_id"]);
  };

  const handleEdit = () => {
    navigate('/edit-row', { state: { row } });
    setVisibleRowId(null);
  };

  const handleDelete = () => {
    deleteRow(entityInternalName, row["public_id"])
      .then(resp => {
        refreshTable();
      })
      .catch(error => {
        console.error(error);
      });
    setVisibleRowId(null);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setVisibleRowId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <button
        className={styles.menuButton}
        onClick={toggleMenu}
        ref={buttonRef}
      >
        ⋮
      </button>
      {isVisibleRow && (
        <div
          className={styles.menu}
          ref={menuRef}
          style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px`, position: 'absolute' }}
        >
          <button onClick={handleEdit} className={styles.menuItem}>Редактировать</button>
          <button onClick={handleDelete} className={styles.menuItem}>Удалить</button>
        </div>
      )}
    </>
  );
};

export default RowMenu;
