import React from 'react'
import styles from './AuthorizationForm.module.css'

const AuthorizationForm = () => {

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleSubmit = (event) => {
        event.preventDefault()
    } 

    const handleChange = (event) => {
        event.preventDefault()
    }

    const handleLogin = (event) => {
        event.preventDefault()
    }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
        <h1 className={styles.title}>Вход</h1>
        <section className={styles.inputsContainer}>
            <input
                className={styles.input}
                type="text"
                name="username"
                placeholder="Имя пользователя"
                onChange={handleChange}
            />
            <input
                className={styles.input}
                type='password'
                name="password"
                placeholder="Пароль"
                onChange={handleChange}
            />
        </section>
        <button className={styles.button} type="submit">Войти</button>
    </form>
  )
}

export default AuthorizationForm