import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Dropdown.module.css';
import MainStoreContext from '../../stores/MainStore';

const Dropdown = ({ label }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { attributes, selectedAttributes, setSelectedAttributes } = useContext(MainStoreContext);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        const changeAttributes = () => {
            const prevState = selectedAttributes;

            const index = prevState.findIndex((selected) => selected._id === option._id);

            if (index !== -1) {
                return prevState.filter((_, i) => i !== index);
            } else {
                return [...prevState, option];
            }
        }
        setSelectedAttributes(changeAttributes());
    };

    const isOptionSelected = (option) => {
        return selectedAttributes.some(selected => selected._id === option._id);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.dropdown} ref={dropdownRef}>
            <button className={styles.dropdownToggle} onClick={toggleDropdown}>
                {label}
            </button>
            {isOpen && (
                <ul className={styles.dropdownMenu}>
                    {Array.isArray(selectedAttributes) && attributes.length > 0 ? (
                        attributes.map((option, index) => (
                            <li
                                key={index}
                                className={classNames(styles.dropdownItem, {
                                    [styles.selected]: isOptionSelected(option),
                                })}
                                onClick={() => handleOptionClick(option)}
                            >
                                {isOptionSelected(option) && <span>&#10003; </span>}
                                {option["public_name"]}
                            </li>
                        ))
                    ) : (
                        <li className={styles.dropdownItem}>
                            Нет доступных опций
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

Dropdown.propTypes = {
    label: PropTypes.string.isRequired,
};

export default Dropdown;
