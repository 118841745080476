import React from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from './Tab.module.css';

const TabNavBar = ({
                       navLabel = 'Tab',
                       className = '',
                       isActive = false,
                       onChangeActiveTab = () => {} }) => {

    const classes = classNames(
        styles['tab-bar-item'],
        styles['tab-button'],
        { [styles.active]: isActive },
        className,
    );

    return (
        <>
            <button className={classes} onClick={() => {onChangeActiveTab(navLabel)}}>
                {navLabel}
            </button>
        </>
    );
};

TabNavBar.propTypes = {
    navLabel: PropTypes.string,
    className: PropTypes.string,
    onChangeActiveTab: PropTypes.func,
    isActive: PropTypes.bool,
}

export default TabNavBar;