import axiosInstance from '../../axiosInstance.jsx';

export const getEntity = () => {
    return axiosInstance.get('/admin/entity/');
};

export const getEntityId = (entity_id) => {
    return axiosInstance.get(`/admin/entity/${entity_id}`);
}

export const putEntity = (entity_id) => {
    return axiosInstance.put(`/admin/entity/${entity_id}`);
}