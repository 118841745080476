import React from 'react';
import {Route, Routes} from "react-router-dom";
import Main from "@/pages/Main/Main.jsx";
// import Layout from "@/pages/Layout/Layout.jsx";
import NotFoundPage from "@/pages/NotFound/NotFound.jsx";
import AddRow from "@/pages/AddRow/AddRow.jsx";
import EditRow from '@/pages/EditRow/EditRow.jsx';
import AuthorizationPage from "@/pages/AuthorizationPage/AuthorizationPage.jsx";

const AppRouter = () => {
    return (
        <>
            <Routes>
                    <Route path="/auth" element={<AuthorizationPage/>} />
                {/*<Route path="/" element={<Layout/>} >*/}
                    <Route path="/" element={<Main/>} />
                    <Route path="add-row" element={<AddRow/>} />
                    <Route path="*" element={<NotFoundPage/>} />
                    <Route path="edit-row" element={<EditRow/>} />
                {/*</Route>*/}
            </Routes>
        </>
    );
};

export default AppRouter;