import axiosInstance from '../../axiosInstance.jsx';

// gets all attributes
export const getAttribute = () => {
    return axiosInstance.get('/admin/attribute/');
};

// creates new attribute
export const postAttribute = (data) => {
    return axiosInstance.post('/admin/attribute/', data);
};

//example of data

// {
//   "entity_id": "5eb7cf5a86d9755df3a6c593",
//   "public_name": "string",
//   "description": "string",
//   "column_type": "int",
//   "input_type": "default",
//   "is_nullable": false,
//   "is_modifiable": true
// }

export const getAttributeId = (attribute_id) => {
    return axiosInstance.get(`/admin/attribute/${attribute_id}`);
}

export const putAttributeId = (attribute_id, data) => {
    return axiosInstance.put(`/admin/attribute/${attribute_id}`, data);
}

export const deleteAttributeId = (attribute_id) => {
    return axiosInstance.delete(`/admin/attribute/${attribute_id}`);
}